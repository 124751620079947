import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function DescriptionAlerts() {
  const [showWeatherAlert, setShowWeatherAlert] = React.useState(true);

  return (
    <Stack sx={{ width: '100%' }} spacing={1}>
      {showWeatherAlert && (
        <Alert
          severity="error"
          sx={{ fontWeight: 'bold' }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setShowWeatherAlert(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography textAlign={'left'}>
            <Link 
              href="https://www.usc.edu/emergency/" 
              target="_blank" 
              rel="noopener" 
              underline="hover"
            >
              Click here to visit the USC Emergency Information Page for updates.
            </Link>
          </Typography>
          <Typography textAlign={'left'}>
            <Link
              href="https://www.aqmd.gov/home/air-quality/air-quality-advisories"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
             Click here for the latest wind and weather advisory.
            </Link>
          </Typography>
        </Alert>
      )}
    </Stack>
  );
}
