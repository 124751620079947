import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header from './Header';
import Gauge from './Guage';
import './App.css';
import LineChartAQI from './LineChartAQI';
import LineChartWeather from './LineChartWeather';
import Tabs from './Tabs';
import Weather from './Weather';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import USCMap from './Map';
import AirQualityConditionsTable from './TableAQI';
import "@fontsource/source-sans-pro";
import HeatConditionTable from './TableHeatConditions';
import Footer from './Footer';
import CurrentConditionsActions from './CurrentConditionsActions';
import Alert from './Alert';

const queryClient = new QueryClient();
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Source Sans Pro",
      textTransform: 'none'
    },
  },
});

function App() {

  const [selectedTab, setSelectedTab] = useState('upc');

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <div className='App'>
          <Alert/>
          <header role='banner'>
            <Header />
          </header>
          <nav role='navigation'>
            <Tabs onTabChange={handleTabChange} />
          </nav>
          <div>
            <main role='main'>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12} className='GridContainer'>
                  <Gauge campus={selectedTab} />
                </Grid>
                <Grid item md={6} sm={12} xs={12} className='GridContainer'>
                  <CurrentConditionsActions campus={selectedTab} />
                </Grid>
                <Grid item md={6} sm={12} xs={12} className='GridContainer'>
                  <USCMap campus={selectedTab} />
                </Grid>
                <Grid item md={6} sm={12} xs={12} className='GridContainer'>
                  <Weather campus={selectedTab} />
                </Grid>
                <Grid item md={6} sm={12} xs={12} className='GridContainer'>
                  <LineChartAQI campus={selectedTab} />
                </Grid>
                <Grid item md={6} sm={12} xs={12} className='GridContainer'>
                  <LineChartWeather campus={selectedTab} />
                </Grid>
              </Grid>
              <AirQualityConditionsTable />
              <HeatConditionTable />
            </main>
          </div>
          <Footer />
        </div>

        <ReactQueryDevtools buttonPosition='bottom-right' />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
